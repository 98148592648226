@import "_variable.sass"
@import "_mixin.sass"

// $loopDuration: 1500ms

// @keyframes bombOpacityAnim
//   0%
//     stroke-width: 0
//     animation-timing-function: $easeOutExpo
//   15%
//     stroke-width: 2.5
//     animation-timing-function: linear
//   30%,100%
//     stroke-width: 0

// =bombScaleAnim($name, $x, $y, $rotate, $scale)
//   @keyframes #{$name}
//     0%
//       transform: translate($x,$y) rotate($rotate) scale($scale - 1)
//       animation-timing-function: cubic-bezier(0, 0.7, 0.07, 1)
//     30%,100%
//       transform: translate($x,$y) rotate($rotate) scale($scale)

// +bombScaleAnim('bombScaleAnim1', -20px, 0, 20deg, 1.75)
// +bombScaleAnim('bombScaleAnim2', 30px, 10px, -8deg, 1.75)

main.p-privacy
  // .l-logo
  //   width: 100vw
  //   height: 100vh
  //   position: fixed
  //   top: 0
  //   left: 0
  //   z-index: 2000
  //   background: #222

  //   &>.logo
  //     position: absolute
  //     top: 50%
  //     left: 50%
  //     transform: translate(-50%,-50%)

  //     svg
  //       .logo
  //         opacity: 1

  //       .bomb
  //         stroke-width: 0
  //         transform-origin: 111.8px 167.7px
        
  //       .bomb1
  //         animation: bombOpacityAnim $loopDuration infinite, bombScaleAnim1 $loopDuration infinite
        
  //       .bomb2
  //         animation: bombOpacityAnim $loopDuration infinite, bombScaleAnim2 $loopDuration infinite
  //         animation-delay: 125ms


  //       .goma
  //         transform-origin: 110.2px 163.4px

  .l-container
    .l-content
      max-width: 680px
      margin: 0 auto

      p
        +ja
        +fontMargin(14px, 2, 32px)

        &:last-child
          +setMargin(14px, 2, 0)

      h2
        +jaBold
        +fontMargin(20px, 1.5, 48px, 80px)

+mq(sp)
  main.p-privacy
    .l-container
      .l-content
        max-width: auto

        p
          +ja
          +fontMargin(12px, 2, 24px)

          &:last-child
            +setMargin(12px, 2, 0)
        
        h2
          +jaBold
          +fontMargin(16px, 1.5, 32px, 56px)