@import "_variable.sass"
@import "_mixin.sass"


@keyframes animMapPin
  0%
    r: 0
    opacity: 1
    animation-timing-function: cubic-bezier(0, 0.9, 0.09, 1)
  100%
    r: 32
    opacity: 0

main.p-about
  .l-container
    .l-profile
      margin-bottom: 160px
      display: flex
      justify-content: space-between

      .author
        width: 270px

        .photo
          +img(100%)
          border-radius: 16px
          overflow: hidden
          margin-bottom: 40px
        
        .names
          .position
            +ttlEn
            color: $light-gray
            font-size: 12px
            margin-bottom: 24px
          
          .name
            &.en
              +ttlEnBold
              font-size: 20px
              margin-bottom: 16px
            
            &.ja
              +jaBold
              +kerning(0.25)
              font-size: 14px
      
      .descriptions
        width: calc(100% - 270px - 80px)
        padding-top: 96px

        .description
          &.en
            +en
            +fontMargin(14px, 2, 64px)

          &.ja
            +ja
            +fontMargin(14px, 2)

    .l-map
      margin-bottom: 160px
      position: relative

      .map
        svg
          .pins
            .pin
              .bg
                right: 0
                opacity: 0
                animation: animMapPin 3750ms infinite

              @for $i from 1 through 50
                &:nth-child(#{$i})
                  .bg
                    animation-delay: $i*15ms
      
      .copy
        +ttlEnBold
        font-size: 14px
        position: absolute
        bottom: 96px
        left: 0

    .l-companyProfile
      .title
        +ttlEnBold
        font-size: 20px
        margin-bottom: 80-32px

      .inner
        display: flex
        justify-content: space-between

        .companyProfile
          width: calc(50% - 60px)
          display: flex
          flex-wrap: wrap

          dt,
          dd
            +fontMargin(14px, 1.5, 0, 32px)

          dt
            color: $light-gray
            width: 160px
          
          dd
            width: calc(100% - 160px)

          &.en
            +en

          &.ja
            +ja

+mq(sp)
  main.p-about
    .l-container
      .l-profile
        margin-bottom: 80px
        display: block

        .author
          width: 100%
          margin-bottom: 40px
          display: flex
          justify-content: space-between
          align-items: center

          .photo
            +img(calc(50% - 16px))
            margin-bottom: 0
          
          .names
            width: calc(50% - 16px)

            .position
              +ttlEn
              font-size: 10px
              margin-bottom: 24px
            
            .name
              &.en
                +ttlEnBold
                font-size: 16px
                margin-bottom: 16px
              
              &.ja
                +jaBold
                font-size: 12px
        
        .descriptions
          width: 100%
          padding-top: 0

          .description
            &.en
              +en
              +fontMargin(12px, 2, 40px)

            &.ja
              +ja
              +fontMargin(12px, 2)

      .l-map
        margin-bottom: 96px
      
        .copy
          font-size: 12px
          margin-top: 24px
          position: relative
          top: 0
          left: 0

      .l-companyProfile
        .title
          font-size: 16px
          margin-bottom: 48-24px

        .inner
          display: block

          .companyProfile
            width: calc(100%)

            dt,
            dd
              +fontMargin(12px, 1.5, 0, 24px)

            dt
              color: $light-gray
              width: 124px
            
            dd
              width: calc(100% - 124px)

            &.en
              +en

            &.ja
              +ja
              margin-top: 48-24px