@import "_variable.sass"
@import "_mixin.sass"

.m-title
  +ttlEnBold
  font-size: 40px
  text-align: center
  padding: 224px 0 144px

.m-container
  width: calc(100% - 240px)
  max-width: calc($maxWidth - 240px)
  margin: 0 auto

+mq(sp)
  .m-title
    font-size: 24px
    padding: 128px 0 80px
  
  .m-container
    width: calc(100% - 64px)
    max-width: auto