@import "_variable.sass"
@import "_mixin.sass"

main.p-contact
  .l-container
    .l-email
      text-align: center
      margin-bottom: 160px

      .copy
        +ttlEnBold
        font-size: 20px
        margin-bottom: 32px
      
      .email
        a
          +ttlEnBold
          font-size: 24px
          text-decoration: none
          transition: opacity 500ms $easeOutQuint, transform 500ms $easeOutExpo
          display: inline-block

          +hover
            opacity: 0.25
            transform: translateY(4px)
            transition: opacity 250ms $easeOutQuint, transform 375ms $easeOutExpo

    .l-form
      max-width: 680px
      margin: 0 auto

      .attention
        +en
        color: $light-gray
        text-align: center
        margin-bottom: 64px

      form
        input,
        textarea
          appearance: none
          border: none
          border-radius: 0
          outline: none

        fieldset
          display: flex

          &+fieldset
            margin-top: 40px

          label
            width: 180px
            padding: 4px 40px 0 0
            box-sizing: border-box

            .en
              +en
              font-size: 16px
              margin-bottom: 12px
              display: block
            
            .ja
              +ja
              color: $light-gray
              font-size: 12px
              display: block
          
          .input
            width: calc(100% - 180px)

            input[type=text],
            input[type=email],
            input[type=tel],
            textarea
              +ja
              color: $white
              width: 100%
              background: $dark-gray
              box-sizing: border-box

              &::placeholder
                color: $light-gray
            
            input[type=text],
            input[type=email],
            input[type=tel]
              height: 56px
              padding: 0 24px
            
            textarea
              height: 168px
              padding: 20px 24px
            
            .wpcf7-not-valid-tip
              +en
              color: $red
              font-size: 14px
              margin-top: 16px
              display: block
          
        .submit
          width: 500px
          height: 80px
          margin: 64px auto 0

          input[type=submit]
            +ttlEnBold
            color: $black
            font-size: 16px
            width: 100%
            height: 100%
            background: $white
            border-radius: 8px
            cursor: pointer
            transition: color 500ms $easeOutQuint, background 500ms $easeOutQuint, transform 500ms $easeOutExpo

            +hover
              color: $white
              background: $dark-gray
              transform: translateY(4px)
              transition: color 250ms $easeOutQuint, background 250ms $easeOutQuint, transform 375ms $easeOutExpo

        .wpcf7-response-output
          +en
          font-size: 14px
          text-align: center
          margin: 16px 0 0
          padding: 0
          border: 0


+mq(sp)
  main.p-contact
    .l-container
      .l-email
        margin-bottom: 96px

        .copy
          font-size: 14px
          margin-bottom: 24px
        
        .email
          a
            font-size: 18px

      .l-form
        max-width: auto

        .attention
          font-size: 12px
          margin-bottom: 48px

        form
          fieldset
            display: block

            label
              width: 100%
              margin-bottom: 16px
              padding: 0
              display: block

              .en
                font-size: 14px
                margin: 0 12px 0 0
                display: inline-block
              
              .ja
                display: inline-block
            
            .input
              width: 100%
            
              .wpcf7-not-valid-tip
                +fontMargin(14px, 1.5, 0, 16px)

          .submit
            width: 100%
            height: 64px
            margin: 48px auto 0

            input[type=submit]
              font-size: 14px

          .wpcf7-response-output
            +fontMargin(14px, 1.5, 0, 16px)
            text-align: left