@use "sass:math"
@charset "utf-8"

=mq($device)
  @if $device == pc
    @media only screen and (min-width: $breakpoint+1)
      @content

  @else if $device == sp
    @media only screen and (max-width: $breakpoint)
      @content

=maxWidth
  @media only screen and (min-width: $maxWidth)
    @content

=fontMargin($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0)
  margin-top: floor($margin-top - (math.div(($font-size * $line-height - $font-size), 2)))
  margin-bottom: floor($margin-bottom - (math.div(($font-size * $line-height - $font-size), 2)))
  font-size: $font-size
  line-height: $line-height

=setMargin($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0)
  margin-top: floor($margin-top - (math.div(($font-size * $line-height - $font-size), 2)))
  margin-bottom: floor($margin-bottom - (math.div(($font-size * $line-height - $font-size), 2)))

=fontPadding($font-size: $font-size, $line-height: $line-height, $padding-bottom: 0, $padding-top: 0)
  padding-top: floor($padding-top - (math.div(($font-size * $line-height - $font-size), 2)))
  padding-bottom: floor($padding-bottom - (math.div(($font-size * $line-height - $font-size), 2)))
  font-size: $font-size
  line-height: $line-height

=setPadding($font-size: $font-size, $line-height: $line-height, $padding-bottom: 0, $padding-top: 0)
  padding-top: floor($padding-top - (math.div(($font-size * $line-height - $font-size), 2)))
  padding-bottom: floor($padding-bottom - (math.div(($font-size * $line-height - $font-size), 2)))

=kerning($letter-spacing: 0)
  letter-spacing: #{$letter-spacing}em
  font-feature-settings: 'palt'

=img($width: auto, $height: auto)
  width: $width
  height: $height

  img
    width: 100%
    height: auto

=hover
  @media (hover: hover)
    &:hover
      @content

@font-face
  font-family: 'Unbounded R'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(https://fonts.gstatic.com/s/unbounded/v6/Yq6W-LOTXCb04q32xlpwu8ZfvRIkSQ.woff2) format('woff2')

@font-face
  font-family: 'Unbounded M'
  font-style: normal
  font-weight: 500
  font-display: swap
  src: url(https://fonts.gstatic.com/s/unbounded/v6/Yq6W-LOTXCb04q32xlpwu8ZfvRIkSQ.woff2) format('woff2')

=ja
  font-family: 'Noto Sans JP', sans-serif
  font-weight: 400

=jaBold
  font-family: 'Noto Sans JP', sans-serif
  font-weight: 500

=en
  font-family: 'Nunito Sans', sans-serif
  font-weight: 400

=enBold
  font-family: 'Nunito Sans', sans-serif
  font-weight: 500

=ttlEn
  font-family: 'Unbounded R', sans-serif
  font-weight: 400

=ttlEnBold
  font-family: 'Unbounded M', sans-serif
  font-weight: 500

=ttlEnJa
  font-family: 'Unbounded R', 'Noto Sans JP', sans-serif
  font-weight: 500