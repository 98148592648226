@import "_variable.sass"
@import "_mixin.sass"

@keyframes logoAnim1
  0%,50.1%
    opacity: 1
  50.2%,100%
    opacity: 0

@keyframes logoAnim2
  0%,49.9%
    opacity: 0
  50%,100%
    opacity: 1

.grecaptcha-badge
  opacity: 0

body
  color: $white
  background: $bg-gray
  font-size: 14px
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

li
  list-style: none

a
  color: $white

.l-header
  width: 100vw
  position: fixed
  top: 0
  left: 0
  z-index: 1000

  .logo
    width: 88px
    height: 64.2px
    position: absolute
    top: 40px
    left: calc(50% - 44px + 2px)
    transition: opacity 500ms $easeOutQuint

    svg
      width: 100%
      height: 100%

      .f1,
      .f2
        will-change: opacity
      
      .f2
        opacity: 0
    
    &.is-loading
      svg
        .f1,
        .f2
          opacity: 0
        
        .f1
          animation: logoAnim1 250ms linear infinite

        .f2
          animation: logoAnim2 250ms linear infinite

    a
      transition: opacity 500ms $easeOutQuint

      +hover
        opacity: 0.25
        transition: opacity 250ms $easeOutQuint
    
    &.is-disable
      opacity: 0
      pointer-events: none
      transition: opacity 250ms $easeOutQuint

  .navBtn
    width: 24+32px
    height: 11+32px
    position: absolute
    top: 68-16px
    right: 64-16px
    z-index: 2
    cursor: pointer
    transition: transform 500ms $easeOutCubic, opacity 500ms $easeOutQuint

    +hover
      opacity: 0.25
      transition: transform 500ms $easeOutCubic, opacity 250ms $easeOutQuint

    .l
      width: 24px
      height: 1px
      background: $white
      margin-left: 16px

      &:nth-child(1)
        margin-top: 16px

      &:nth-child(2)
        margin-top: 5px

      &:nth-child(3)
        margin-top: 5px
      
      &:nth-child(1),
      &:nth-child(3)
        transition: transform 250ms $easeOutCubic, opacity 375ms $easeOutCubic
    
    &.is-open
      transform: translate(24px, -24px)
      transition: transform 500ms $easeOutCubic, opacity 500ms $easeOutQuint

      .l
        &:nth-child(1)
          transform: translateY(5px)

        &:nth-child(3)
          transform: translateY(-5px)

        &:nth-child(1),
        &:nth-child(3)
          opacity: 0
          transition: transform 250ms $easeOutCubic, opacity 375ms $easeOutCubic

  .l-nav
    width: 480px
    height: 100vh
    background: $dark-gray
    position: fixed
    top: 0
    right: 0
    z-index: 1
    transform: translateX(100%)
    transition: transform 750ms $easeOutExpo

    &.is-open
      transform: translateX(0)
      transition: transform 500ms cubic-bezier(0, 0.9, 0.09, 1)
    
    .inner
      width: 100%
      height: 100%
      box-sizing: border-box
      padding: 80-20px 80px 144px
      overflow-y: auto

      &::-webkit-scrollbar
        width: 16px

      &::-webkit-scrollbar-track
        background: $dark-gray
      
      &::-webkit-scrollbar-thumb
        background: $white
        border: 6px solid $dark-gray

      .nav
        margin-bottom: 48-20-16px

        li
          a
            +ttlEnBold
            +fontPadding(24px, 1, 20px, 20px)
            text-decoration: none
            display: block
            transition: opacity 500ms $easeOutQuint, transform 500ms $easeOutExpo

            +hover
              opacity: 0.25
              transform: translateX(12px)
              transition: opacity 250ms $easeOutQuint, transform 375ms $easeOutExpo
      
      .works
        li
          a
            +ttlEnJa
            +fontPadding(10px, 1.75, 16px, 16px)
            text-decoration: none
            display: block
            transition: opacity 500ms $easeOutQuint, transform 500ms $easeOutExpo

            +hover
              opacity: 0.25
              transform: translateX(12px)
              transition: opacity 250ms $easeOutQuint, transform 375ms $easeOutExpo

    .footer
      width: calc(100% - 40px)
      background: $dark-gray
      box-sizing: border-box
      padding: 40-8px 40px 40-8px 40-8px
      display: flex
      justify-content: space-between
      align-items: center
      position: absolute
      bottom: 0
      left: 0
      z-index: 1

      &:before
        content: ''
        width: 100%
        height: 64px
        background: linear-gradient(360deg, $dark-gray 0%, rgba(34, 34, 34, 0) 100%)
        position: absolute
        top: -64px
        left: 0

      .nav
        display: flex
        align-items: center

        li
          a
            +ttlEn
            font-size: 10px
            text-decoration: none
            padding: 8px
            display: block
            transition: opacity 500ms $easeOutQuint

            +hover
              opacity: 0.25
              transition: opacity 250ms $easeOutQuint

          &.instagram
            a
              font-size: 0

          &+li
            margin-left: 16-8px
      
      .copyright
        +ttlEn
        font-size: 10px
        transform: scale(0.8)
        transform-origin: 100% 50%
        margin-right: -40px

.l-footer
  width: 100%
  box-sizing: border-box
  padding: 200-8px 64px 64-8px 64-8px
  display: flex
  justify-content: space-between
  align-items: center

  .nav
    display: flex
    align-items: center

    li
      a
        +ttlEn
        color: $light-gray
        font-size: 10px
        text-decoration: none
        padding: 8px
        display: block
        transition: color 500ms $easeOutQuint

        +hover
          color: $white
          transition: color 250ms $easeOutQuint

          svg
            path
              fill: $white
              transition: fill 250ms $easeOutQuint

        svg
          path
            fill: $light-gray
            transition: fill 500ms $easeOutQuint

      &.instagram
        a
          font-size: 0
      
      &+li
        margin-left: 16-8px
  
  .copyright
    +ttlEn
    color: $light-gray
    font-size: 10px
    transform: scale(0.8)
    transform-origin: 100% 50%

+mq(sp)
  .l-header
    .logo
      +img(56px)
      top: 24px
      left: calc(50% - 28px + 2px)

    .navBtn
      top: 40-16px
      right: 24-16px
      -webkit-tap-highlight-color: transparent
      
      &.is-open
        transform: translate(-8px, -12px)

    .l-nav
      width: 100%
      height: 100vh
      height: 100dvh
      
      .inner
        padding: 48-16px 48px 112px

        .nav
          margin-bottom: 40-16-12px

          li
            a
              +fontPadding(20px, 1, 16px, 16px)
        
        .works
          li
            a
              +fontPadding(10px, 1.75, 12px, 12px)

      .footer
        padding: 32-8px 32px 32-8px 32-8px

        &:before
          height: 40px
          top: -40px

  .l-footer
    padding: 96-8px 32px 32-8px 32-8px