@import "_variable.sass"
@import "_mixin.sass"

main.p-error
  .l-container
    .l-content
      max-width: 680px
      margin: 0 auto

      .en,
      .ja
        +fontMargin(14px, 2)
        text-align: center

      .en
        +en
      
      .ja
        +ja
        +setMargin(14px, 2, 0, 40px)

+mq(sp)
  main.p-error
    .l-container
      .l-content
        max-width: auto

        .en,
        .ja
          +fontMargin(12px, 2)
          text-align: left

          br
            display: none
        
        .ja
          +ja
          +setMargin(12px, 2, 0, 32px)