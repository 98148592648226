@import "_variable.sass"
@import "_mixin.sass"

main.p-archiveWorks
  .l-container
    .l-tag
      .tag
        display: flex
        margin: -8px 0 48-8px -8px

        li
          &+li
            margin-left: 40-8px

          a
            +en
            color: $light-gray
            font-size: 14px
            text-decoration: none
            padding: 8px
            display: block
            transition: color 500ms $easeOutQuint

            +hover
              color: $white
              transition: color 250ms $easeOutQuint
          
          &.is-current
            a
              color: $white
              pointer-events: none

    .l-works
      .works
        display: flex
        flex-wrap: wrap
        align-items: flex-start
        margin-bottom: 128-80px

        li
          width: calc((100% - 128px) / 3)
          margin-bottom: 80px
          position: relative
          opacity: 1
          transition: opacity 500ms $easeOutQuart 100ms

          &:nth-child(3n-2)
            top: 128px
            margin-right: 64px

          &:nth-child(3n-1)
            top: 64px
            margin-right: 64px

          a
            text-decoration: none
            display: block

            .thumb
              +img(100%)
              margin-bottom: 32px
              transition: transform 500ms $easeOutQuint
            
            .title
              +ttlEnJa
              +fontMargin(12px, 1.75)
              text-align: center
              padding: 0 24px
              transition: transform 500ms $easeOutQuint
            
            +hover
              .thumb
                transform: scale(1.1)
                transition: transform 500ms $easeOutQuint
              
              .title
                transform: translateY(10px)
                transition: transform 500ms $easeOutQuint
        
      &.is-hover
        li:not(:hover)
          opacity: 0.25
          transition: opacity 375ms $easeOutQuart

main.p-singleWorks
  .l-container
    .l-back
      margin-bottom: 96px
      padding-top: 184px

      .back
        margin: -8px

        a
          +en
          font-size: 14px
          color: $light-gray
          text-decoration: none
          display: inline-block
          padding: 8px
          transition: color 500ms $easeOutQuint

          .icn
            margin-right: 12px

            svg
              path
                fill: $light-gray
                transition: fill 500ms $easeOutQuint 

          +hover
            color: $white
            transition: color 250ms $easeOutQuint

            .icn
              svg
                path
                  fill: $white
                  transition: fill 250ms $easeOutQuint

    .l-visual
      width: calc(100% + 240px)
      aspect-ratio: 16 / 9
      margin: 0 0 112px -120px
      position: relative
      cursor: pointer

      &.is-nolink
        cursor: auto

      +hover
        .play
          &:after
            transform: scale(1.2)
            transition: transform 375ms $easeOutExpo

      +maxWidth
        width: 100%
        margin-left: 0

      &.is-play
        .visual
          opacity: 0
          pointer-events: none

        .play
          opacity: 0
          pointer-events: none

      .visual
        +img(100%)
        position: relative
        z-index: 1
      
      .play
        width: 129px
        height: 80px
        position: absolute
        top: 50%
        left: 50%
        z-index: 2
        transform: translate(-50%,-50%)

        svg
          position: absolute
          top: 50%
          left: 51%
          z-index: 1
          transform: translate(-50%,-50%)
        
        &:after
          content: ''
          width: 100%
          height: 100%
          background: $white
          border-radius: 12px        
          position: absolute
          top: 0
          left: 0
          z-index: 0
          transition: transform 500ms $easeOutExpo
      
      .youtube
        width: 100%
        aspect-ratio: 16 / 9
        position: absolute
        top: 0
        left: 0
        z-index: 0

        iframe
          width: 100%
          height: 100%
    
    .l-description

      .tag
        display: flex
        margin-bottom: 40px

        li
          +en
          color: $light-gray
          font-size: 12px

          &+li
            margin-left: 24px
      
      .inner
        display: flex
        justify-content: space-between
        align-items: flex-start

        .title
          +ttlEnJa
          +fontMargin(24px, 1.75)
          width: calc(61.8% - 120px)
        
        .credit
          width: calc(38.2%)
          display: flex
          flex-wrap: wrap
          align-content: flex-start
          margin-top: -34px

          dt,
          dd
            +en
            +fontMargin(14px, 1.5, 0, 32px)

          dt
            color: $light-gray
            width: 200px
            padding-right: 40px
            box-sizing: border-box
          
          dd
            width: calc(100% - 200px)
    
    .l-imgs
      margin-top: 160px

      .img
        +img(100%)

        &+.img
          margin-top: 40px

+mq(sp)
  main.p-archiveWorks
    .l-container
      .l-tag
        .tag
          display: block
          margin: -12px 0 80-12px

          li
            &+li
              margin-left: 0

            a
              font-size: 14px
              padding: 12px 0
              text-align: center

      .l-works
        .works
          display: block
          margin-bottom: -80px

          li
            width: 100%
            margin-bottom: 80px

            &:nth-child(3n-2)
              top: 0

            &:nth-child(3n-1)
              top: 0

            a
              .title
                +fontMargin(11px, 1.75)

  main.p-singleWorks
    .l-container
      .l-back
        margin-bottom: 48px
        padding-top: 112px

        .back
          a
            +en
            font-size: 12px

            .icn
              margin-right: 8px
              display: inline-block
              transform: scale(0.85) translateY(1px)
      
      .l-visual
        width: 100vw
        margin: 0 0 48px -32px
        
        .play
          width: 64px
          height: 40px

          &:after
            border-radius: 6px

          svg
            transform: translate(-50%,-50%) scale(0.5)
      
      .l-description
        .tag
          margin-bottom: 32px

          li
            font-size: 10px

            &+li
              margin-left: 20px
        
        .inner
          display: block

          .title
            +fontMargin(16px, 1.75, 48px)
            width: 100%
          
          .credit
            width: 100%
            margin-top: -24px

            dt,
            dd
              +fontMargin(12px, 1.5, 0, 24px)

            dt
              width: 124px
              padding-right: 40px
            
            dd
              width: calc(100% - 124px)
      
      .l-imgs
        margin-top: 80px

        .img
          &+.img
            margin-top: 32px