@import "_variable.sass"
@import "_mixin.sass"

main.p-index
  .l-index
    // position: fixed
    // top: 0
    // left: 0
    // transform: scale(0.25) translate(100vw, 150vw)

    &:before
      content: ''
      background: #f00
      width: 1px
      height: 100%
      position: fixed
      top: 0
      left: 50%
      z-index: 100
      opacity: 0.5

    &:after
      content: ''
      background: #f00
      width: 100%
      height: 1px
      position: fixed
      top: 50%
      left: 0
      z-index: 100
      opacity: 0.5
    
    &:before,
    &:after
      display: none

    .l-works
      //background: #003
      width: 225vw
      height: 225vw
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      margin-top: 25vw
      margin-left: 109.5vw
      opacity: 0

      &.is-loaded
        opacity: 1
        transition: opacity 2000ms $easeOutCubic

      +maxWidth
        width: calc($maxWidth * 2.25)
        height: calc($maxWidth * 2.25)
        margin-top: calc($maxWidth * 0.25)
        margin-left: calc($maxWidth * 1.095)

      // .round
      //   width: 100%
      //   height: 100%
      //   box-sizing: border-box
      //   border: 1px solid #fff
      //   border-radius: 50%
      //   display: none
      
      .works
        position: absolute
        top: 0
        left: 0
        width: calc(100vw - 240px)
        height: calc((100vw - 240px) * 0.5625)
        will-change: transform
        // width: calc((100vw - 240px) * 0.5625)
        // width: 100px
        // height: 100px
        // background: #00c url("../img/thumb_2.jpg") no-repeat 50% 50%
        // background-size: cover
        // text-align: center
        // line-height: calc((100vw - 240px) * 0.5625)
        // font-size: 24px
        // +ttlEnBold

        +maxWidth
          width: calc($maxWidth - 240px)
          height: calc(($maxWidth - 240px) * 0.5625)

        .thumb
          img,
          video
            width: 100%

        .title
          +ttlEnJa
          font-size: 20px
          text-align: center
          width: 100%
          position: absolute
          top: calc(50% - 10px)
          left: 0
          transform: translateY(20px)
          opacity: 0

          +mq(pc)
            transition: opacity 375ms $easeOutCubic, transform 1ms linear 375ms
        
        &:hover
          .title
            +mq(pc)
              opacity: 1
              transform: translateY(0)
              transition: opacity 500ms $easeOutCubic, transform 500ms $easeOutCubic

  .l-footer
    display: none

+mq(sp)
  main.p-index
    .l-index
      .l-works
        width: 400vw
        height: 400vw
        margin-top: 75vw
        margin-left: 186vw

        .works
          width: calc(100vw - 64px)
          height: calc((100vw - 64px))

          .thumb
            height: 100%

            img,
            video
              width: 100%
              height: 100%
              object-fit: cover
          
          .title
            display: none

html.lenis
  height: auto

.lenis.lenis-smooth
  scroll-behavior: auto

.lenis.lenis-smooth [data-lenis-prevent]
  overscroll-behavior: contain

.lenis.lenis-stopped
  overflow: hidden

.lenis.lenis-scrolling iframe
  pointer-events: none