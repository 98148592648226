@import "_variable.sass"
@import "_mixin.sass"

body
  &.barba-once
    .l-header
      .logo
        transform: translateY(calc(50vh - 100%))

        svg
          transform: scale(2)
      
      .navBtn
        opacity: 0
        transform: translateX(40px)

    .l-main:not(.p-index)
      opacity: 0
      transform: translateY(64px)
    
  &.barba-once-to
    .l-header
      .logo
        transform: translateY(0)
        transition: transform 1500ms $easeOutQuint

        svg
          transform: scale(1)
          transition: transform 750ms $easeOutCubic

      .navBtn
        opacity: 1
        transform: translateX(0)
        transition: opacity 750ms $easeOutCubic, transform 1000ms $easeOutQuint

    .l-main:not(.p-index)
      opacity: 1
      transform: translateY(0)
      transition: opacity 750ms $easeOutCubic, transform 1000ms $easeOutQuint
      transition-delay: 250ms

  &.barba-leave
    .l-transition
      opacity: 1

      .transition
        transform: translate(-50%,-50%) scale(0.1)

  &.barba-leave-to
    .l-transition
      .transition
        opacity: 1
        transform: translate(-50%,-50%) scale(1)

        &.first
          transition: opacity 0ms linear, transform 750ms $easeOutExpo
        
        &.second
          transition: opacity 0ms linear, transform 750ms $easeOutQuad
          transition-delay: 125ms

  &.barba-enter
    .l-transition
      .transition
        opacity: 1
        transform: translate(-50%,-50%) scale(1)
    
    .l-main:not(.p-index)
      opacity: 0
      transform: translateY(40px)

  &.barba-enter-to
    .l-transition
      .transition
        opacity: 0
        transform: translate(-50%,-50%) scale(0.1)
    
    .l-main:not(.p-index)
      opacity: 1
      transform: translateY(0)
      transition: opacity 750ms $easeOutCubic, transform 1000ms $easeOutQuint

  .l-transition
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    left: 0
    z-index: 100
    pointer-events: none
    overflow: hidden

    .transition
      width: 200vw
      height: 200vw
      position: absolute
      top: 40%
      left: 50%
      opacity: 0
      transform: translate(-50%,-50%) scale(0.1)

      svg
        width: 100%
        height: 100%
      
      &.first
        svg
          path
            fill: #222
      
      &.second
        svg
          path
            fill: $bg-gray

+mq(sp)
  body
    &.barba-once
      .l-header
        .logo
          transform: translateY(calc(50vh - 100%))
          transform: translateY(calc(50dvh - 100%))

    .l-transition
      .transition
        width: 200vh
        width: 200dvh
        height: 200vh
        height: 200dvh
        top: 42vh
        top: 42dvh